<template>
  
<div class="bg-red">
    <header class="header hidden">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
            </a>
        </div>
        <div class="header-center">
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="cards-wrapper">
                <div class="card justify-center">
                    <div class="card-message">
                        <div class="card-message-subtitle">
                            <div class="card-message-subtitle-flag">
                                <img src="img/hungary-flag-icon.png" alt="Hungary">
                            </div>
                            <div class="card-message-subtitle-name">
                                Tőzsdecápa
                            </div>
                        </div>
                        <div class="card-message-title">
                            Most nem tudsz játékot indítani.
                        </div>
                    </div>
                </div>
                <div class="card justify-center">
                    <div class="card-message">
                        <div class="card-message-subtitle">
                            <div class="card-message-subtitle-flag">
                                <img src="img/uk-flag-icon.png" alt="United Kingdom">
                            </div>
                            <div class="card-message-subtitle-name">
                                Stock market shark
                            </div>
                        </div>
                        <div class="card-message-title">
                            You can't start a game right now.
                        </div>
                    </div>
                </div>
                <div class="card justify-center">
                    <div class="card-message">
                        <div class="card-message-subtitle">
                            <div class="card-message-subtitle-flag">
                                <img src="img/china-flag-icon.png" alt="China">
                            </div>
                            <div class="card-message-subtitle-name">
                                股市鲨鱼
                            </div>
                        </div>
                        <div class="card-message-title">
                            你现在不能在这里开始游戏。
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
        <div class="container mb-32">
            <div class="card card-bg-white card-color-red">
                <div class="card-minutes">
                    <div class="card-minutes-icon">
                        <img src="img/fail-icon.png" alt="Fail icon">
                    </div>
                    <div class="card-minutes-info">
                        <div class="card-minutes-info-text-left">
                            <p>várakozási idő</p>
                            <p>Available in</p>
                            <p>直到它溶解止</p>
                        </div>
                        <div class="card-minutes-info-numbers">
                            <strong>15</strong>
                        </div>
                        <div class="card-minutes-info-text-right">
                            <p>perc</p>
                            <p>minutes</p>
                            <p>分钟</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>